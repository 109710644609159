<template>
  <div class="TalentsIntro">
    <b-card img-alt="Image" img-top tag="article" class="mb-2" :body-class="'p-1'">
      <b-card-header>
        <b-card-title class="text-dark mb-0"> {{ preset.texts.name_talent }} </b-card-title>
      </b-card-header>
      <b-card-body class="m-1 text-dark text-justify lead" body-class="p-10" v-html="preset.texts.talents_intro_text" />
      <b-card-footer class="d-flex justify-content-end">
        <button class="btn btn-primary" @click="$emit('nextStep')">{{ $t("GENERAL.NEXT") }}</button>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "talents-intro",
  props: {
    preset: {
      type: Object,
      required: true,
    },
  },
};
</script>
