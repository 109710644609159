<template>
  <div class="LaRangeTalents">
    <b-row class="text-center d-flex d-sm-none mt-2" no-gutters>
      <b-col v-if="answer === null" v-html="texts.choose_one" />
      <b-col v-if="answer <= 20 && answer !== null" v-html="texts.always_a" />
      <b-col v-if="answer > 20 && answer <= 40 && answer !== null" v-html="texts.often_a" />

      <b-col v-if="answer > 40 && answer <= 60 && answer !== null" v-html="texts.neutral" />
      <b-col v-if="answer > 60 && answer <= 80 && answer !== null" v-html="texts.often_b" />
      <b-col v-if="answer > 80 && answer !== null" v-html="texts.always_b" />
    </b-row>
    <b-row class="text-center mt-2" no-gutters>
      <b-col class="slider-h slider-top-left" :class="answer < 1 || answer > 20 ? 'bg-light-primary' : 'bg-primary'"> </b-col>
      <b-col class="slider-margin slider-h" :class="answer <= 20 || answer > 40 ? 'bg-light-primary' : 'bg-primary'"> </b-col>
      <b-col class="slider-margin slider-h" :class="answer <= 40 || answer > 60 ? 'bg-light-primary' : 'bg-primary'"> </b-col>
      <b-col class="slider-margin slider-h" :class="answer <= 60 || answer > 80 ? 'bg-light-primary' : 'bg-primary'"> </b-col>
      <b-col class="slider-h slider-margin slider-top-right" :class="answer <= 80 ? 'bg-light-primary' : 'bg-primary'"> </b-col>
    </b-row>

    <b-row>
      <b-col> <input @mousedown="mousedown" @mouseup="mouseup" type="range" name="asdfadsf" class="w-100 slider" min="1" max="100" v-model="answer" /></b-col>
    </b-row>

    <b-row class="text-center d-none d-sm-flex mt-2" no-gutters>
      <b-col :class="{ 'text-muted': answer < 1 || answer > 20 }" v-html="texts.always_a" />
      <b-col :class="{ 'text-muted': answer <= 20 || answer > 40 }" v-html="texts.often_a" />
      <b-col :class="{ 'text-muted': answer <= 40 || answer > 60 }" v-html="texts.neutral" />
      <b-col :class="{ 'text-muted': answer <= 60 || answer > 80 }" v-html="texts.often_b" />
      <b-col :class="{ 'text-muted': answer <= 80 }" v-html="texts.always_b" />
    </b-row>

    <b-row> </b-row>
  </div>
</template>

<style scoped src="./_LaRangeTalents.css" type="css"></style>
<script>
export default {
  name: "la-range-talents",
  props: {
    texts: Object,
    value: Object,
  },
  data() {
    return {};
  },
  computed: {
    answer: {
      get() {
        return this.value.r <= 0 ? null : this.value.r;
      },
      set(value) {
        // eslint-disable-next-line vue/no-mutating-props
        this.value.r = value;
        this.$emit("data-changed", value);
      },
    },
  },
  methods: {
    mousedown() {
      if (this.answer == null) this.answer = 50;
    },
    mouseup() {
      this.$emit("data-changed");
    },
  },
  mounted() {
    // console.log(this.value);
  },
};
</script>
