<template>
  <div class="text-white">
    <b-row class="justify-content-md-center" v-if="showLoading">
      <b-col cols="12" md="auto"><b-spinner></b-spinner></b-col>
    </b-row>
    <div class="mb-10" v-if="!showLoading && false">
      <b-card class="text-dark">
        <b-media>
          <template v-slot:aside>
            <b-img width="64" alt="placeholder" :src="imagePath(presetData.photo_url)" class="rounded-0" style="height: 100px; width: auto"></b-img>
          </template>

          <h1 class="mt-10 mb-6">{{ presetData.preset_texts.name | uppercase }}</h1>
        </b-media>

        <hr />
        <b-media>
          <div class="lead text-justify" v-html="presetData.preset_texts.description"></div>
        </b-media>
      </b-card>
    </div>

    <div
      v-if="!showLoading"
      class="card card-custom bgi-no-repeat bgi-size-cover gutter-b card-stretch b"
      :style="{
        backgroundImage: `url(${backgroundImage('media/headers/' + presetData.codename + '.jpg')})`,
      }"
    >
      <!--begin::Body-->
      <div class="card-body" style="">
        <div class="row">
          <div class="col-sm"></div>
          <div class="col-sm d-flex flex-column align-items-end justify-content-end">
            <b-media class="w-100">
              <template v-slot:aside>
                <b-img width="64" alt="placeholder" :src="imagePath(presetData.photo_url)" class="rounded-0" style="height: 100px; width: auto"></b-img>
              </template>

              <h1 class="mt-10 mb-6 p-2 text-right" style="">{{ presetData.preset_texts.name | uppercase }}</h1>
            </b-media>
            <hr class="border border-white w-100" />
          </div>
        </div>

        <div class="row">
          <div class="col-sm"></div>
          <div class="col-sm d-flex flex-column align-items-end justify-content-end" style="min-height: 12em">
            <div class="lead text-justify font-weight-bold" v-html="presetData.preset_texts.description" v-if="false"></div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </div>

    <div class="row justify-content-end mb-10" v-if="false">
      <div class="col-4">
        <router-link :to="{ name: 'dashboard' }" class="btn btn-success btn-lg font-weight-bold py-3 px-6 float-right">
          {{ $t("GENERAL.DASHBOARD") }}
        </router-link>
      </div>
    </div>
    <transition name="fade-in-up">
      <component
        v-if="!showLoading"
        v-bind:is="engine"
        @save-data="saveData"
        :preset="presetData"
        :saveContent="saveContent"
        :currentToken="currentToken"
      ></component>
    </transition>
    <b-toast
      id="my-toast"
      variant="success"
      toaster="b-toaster-top-center"
      auto-hide-delay="1000"
      body-class="hidden"
      header-class="font-weight-bolder text-dark"
    >
      <template #toast-title>
        <i class="fa fa-save mr-4 text-success"></i>
        {{ $t("GENERAL.SAVING_DATA") }}
      </template>
    </b-toast>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Big5Engine from "@/view/pages/content-engines/Big5Engine/Big5Engine";
import GripEngine from "@/view/pages/content-engines/GripEngine/GripEngine";
import VimeoEngine from "@/view/pages/content-engines/VimeoEngine/VimeoEngine";
import MixedCourseEngine from "@/view/pages/content-engines/MixedCourseEngine/MixedCourseEngine";
import TalentsEngine from "@/view/pages/content-engines/TalentsEngine/TalentsEngine";
import { GET_PRESET_DATA_CONTENT, GET_SAVE_DATA_CONTENT, UPDATE_SAVE_DATA_CONTENT } from "@/core/services/store/token.module";
import RunContentLastPage from "@/view/pages/content-components/RunContentLastPage/RunContentLastPage";
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from "@/core/services/store/htmlclass.module";
import LAUtils from "@/core/services/LAUtils.service";
import uppercase from "@/core/mixins/uppercase.filter";

export default {
  name: "run-content",
  // eslint-disable-next-line vue/no-unused-components
  components: {
    RunContentLastPage,
    GripEngine,
    Big5Engine,
    TalentsEngine,
    VimeoEngine,
    MixedCourseEngine,
  },
  mixins: [uppercase],
  // components: {
  //   "big5-engine": () => import("../content-engines/Big5Engine/Big5Engine"),
  // },
  data() {
    return {
      showLoading: true,
      currentToken: "",
      presetData: {},
      saveContent: {},
      mode: "",
      type: "",
      saveFn: null,
    };
  },
  methods: {
    imagePath(path) {
      return LAUtils.contentImagePath(path);
    },
    backgroundImage(path) {
      return process.env.BASE_URL + path;
    },
    updateData() {},
    saveData() {
      this.$store
        .dispatch("token/" + UPDATE_SAVE_DATA_CONTENT, {
          token: this.currentToken,
          save_content: this.saveContent,
        })
        .then(() => {
          // TheToaster.saving();
          this.$bvToast.show("my-toast");
        });
    },
  },
  computed: {
    engine() {
      return this.presetData.engine + "-engine";
    },
  },
  beforeMount() {
    let self = this;
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
    setTimeout(() => {
      self.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 1000);
  },
  mounted() {
    let self = this;
    this.currentToken = this.$route.params.token;
    this.$store.dispatch("token/" + GET_PRESET_DATA_CONTENT, this.currentToken).then((response) => {
      self.presetData = response.data;
      this.$store.dispatch("token/" + GET_SAVE_DATA_CONTENT, this.currentToken).then((response) => {
        self.saveContent = response.data;
        self.showLoading = false;
      });
    });
    this.$store.dispatch(SET_BREADCRUMB, []);
    this.updateData();
  },
  beforeDestroy() {},
};
</script>

<style scoped></style>
