<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <b-card-header>
        <b-card-title class="text-dark mb-0">
          {{ preset.texts.name_skill }}
        </b-card-title>
      </b-card-header>
      <!--begin: Wizard-->
      <div class="wizard wizard-1" id="kt_wizard_skills" data-wizard-state="step-first" data-wizard-clickable="true">
        <!--begin: Wizard Nav-->
        <div class="wizard-nav border-bottom">
          <div class="wizard-steps p-8 p-lg-10">
            <div class="wizard-step" data-wizard-type="step" data-wizard-state="current">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-users"></i>
                <h3 class="wizard-title font-weight-bolder">{{ preset.texts[PEOPLE_DOMAIN] }}</h3>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-responsive"></i>
                <h3 class="wizard-title font-weight-bolder">{{ preset.texts[INFO_DOMAIN] }}</h3>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-gift"></i>
                <h3 class="wizard-title font-weight-bolder">{{ preset.texts[STUFFS_DOMAIN] }}</h3>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav-->

        <!--begin: Wizard Body-->
        <div class="row justify-content-center px-8 my-10 px-lg-10">
          <div class="col-xl-12 col-xxl-10">
            <!--begin: Wizard Form-->
            <form class="form" id="kt_form">
              <!--begin: Wizard Step 1-->
              <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                <h2 class="text-dark font-weight-light text-justify mb-10">
                  {{ preset.texts.info_skill }}
                </h2>
                <skill-range
                  v-for="(questionIndex, index) in getQuestionsIndex(PEOPLE_DOMAIN)"
                  :key="index"
                  v-model="answers[questionIndex]"
                  :preset="questions[questionIndex]"
                  :texts="preset.texts"
                />
              </div>
              <!--end: Wizard Step 1-->

              <!--begin: Wizard Step 2-->
              <div class="pb-5" data-wizard-type="step-content">
                <h2 class="text-dark font-weight-light text-justify mb-10">
                  {{ preset.texts.info_skill }}
                </h2>
                <skill-range
                  v-for="(questionIndex, index) in getQuestionsIndex(INFO_DOMAIN)"
                  :key="index"
                  v-model="answers[questionIndex]"
                  :preset="questions[questionIndex]"
                  :texts="preset.texts"
                />
              </div>
              <!--end: Wizard Step 2-->

              <!--begin: Wizard Step 3-->
              <div class="pb-5" data-wizard-type="step-content">
                <h2 class="text-dark font-weight-light text-justify mb-10">
                  {{ preset.texts.info_skill }}
                </h2>
                <skill-range
                  v-for="(questionIndex, index) in getQuestionsIndex(STUFFS_DOMAIN)"
                  :key="index"
                  v-model="answers[questionIndex]"
                  :preset="questions[questionIndex]"
                  :texts="preset.texts"
                />
              </div>
              <!--end: Wizard Step 3-->

              <!--begin: Wizard Actions -->
              <div class="d-flex justify-content-end border-top pt-10">
                <div class="mr-2" v-if="false">
                  <button class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4" @click="goPrev">
                    {{ $t("GENERAL.PREVIOUS") }}
                  </button>
                </div>
                <div>
                  <button v-on:click="submit" class="btn btn-success font-weight-bold text-uppercase px-9 py-4" data-wizard-type="action-submit" v-if="false">
                    Submit
                  </button>
                  <button class="btn btn-primary font-weight-bold text-uppercase px-9 py-4" @click="goToNext">
                    {{ $t("GENERAL.NEXT") }}
                  </button>
                </div>
              </div>
              <!--end: Wizard Actions -->
            </form>
            <!--end: Wizard Form-->
          </div>
        </div>
        <!--end: Wizard Body-->
      </div>
    </div>
    <!--end: Wizard-->
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-1.scss";
</style>

<script>
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import SkillRange from "@/view/pages/content-engines/TalentsEngine/components/SkillMechanic/components/SkillRange/SkillRange";
import TheToaster from "@/core/services/the-toaster";

export default {
  name: "skill-mechanic",
  components: { SkillRange },
  props: {
    preset: Object,
    saveContent: Object,
  },
  data() {
    return {
      wizard: null,
    };
  },
  computed: {
    answers: {
      get() {
        return this.saveContent.saveData.answers.skills;
      },
    },
    questions: {
      get() {
        return this.preset.questions.skills;
      },
    },
    saveData: {
      get() {
        return this.saveContent.saveData;
      },
    },
    PEOPLE_DOMAIN: {
      get() {
        return "people";
      },
    },
    INFO_DOMAIN: {
      get() {
        return "info";
      },
    },
    STUFFS_DOMAIN: {
      get() {
        return "things";
      },
    },
    DOMAINS: {
      get() {
        return [this.PEOPLE_DOMAIN, this.INFO_DOMAIN, this.STUFFS_DOMAIN];
      },
    },
  },
  mounted() {
    // Initialize form wizard
    this.wizard = new KTWizard("kt_wizard_skills", {
      startStep: 1, // initial active step number
      clickableSteps: false, // allow step clicking
    });
    this.wizard.on("changed", function (/*wizardObj*/) {
      // setTimeout(() => {
      //
      // }, 200);
    });

    while (this.wizard.getStep() < this.saveData.skills_page + 1) {
      this.wizard.goNext();
    }

    // wizard.(3);
  },
  methods: {
    getQuestionsDomain(domain) {
      return this.questions.filter((elem) => elem.domain === domain);
    },
    getQuestionsIndex(domain) {
      let res = [];
      this.questions.forEach((elem, i) => {
        if (elem.domain === domain) res.push(i);
      });

      return res;
    },
    goToNext() {
      let self = this;

      // console.log(this.wizard.getStep());

      let answerIndexes = this.getQuestionsIndex(this.DOMAINS[this.wizard.getStep() - 1]);

      let notAnsweredItems = answerIndexes.reduce((notAnswered, item) => {
        if (self.answers[item].r === 0) {
          notAnswered++;
        }
        return notAnswered;
      }, 0);

      if (notAnsweredItems > 0) {
        TheToaster.error(this.preset.texts.answered_warning, false);
      } else {
        if (!this.wizard.isLastStep()) {
          KTUtil.scrollTop(0, 500);
          setTimeout(() => {
            this.wizard.goNext();
            this.saveData.skills_page = this.wizard.getStep() - 1;
          }, 250);
        } else {
          this.$emit("nextStep");
        }
      }
    },
    goPRev() {
      this.wizard.goPRev();
    },
    submit: function (e) {
      e.preventDefault();
      Swal.fire({
        title: "",
        text: "The application has been successfully submitted!",
        icon: "success",
        confirmButtonClass: "btn btn-secondary",
      });
    },
  },
};
</script>
