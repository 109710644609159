<template>
  <div class="TalentMechanic">
    <b-card img-alt="Image" img-top tag="article" class="mb-2" :body-class="'p-1'">
      <b-card-header>
        <b-card-title class="text-dark mb-0">
          {{ preset.texts.name_talent }}
        </b-card-title>
      </b-card-header>
      <b-card-body class="m-1" body-class="p-4  ">
        <p class="text-dark font-weight-light text-justify lead">
          {{ preset.texts.info_talent }}
        </p>

        <b-progress :max="answers.length" height="1.2rem" class="my-10">
          <b-progress-bar :value="currentPage + 1">
            <span
              ><strong>{{ currentPage + 1 }} / {{ answers.length }}</strong></span
            >
          </b-progress-bar>
        </b-progress>

        <transition name="slide-fade">
          <div class="border rounded mt-4 pt-10" v-if="show">
            <h1 class="text-primary text-center mb-5 text-dark" v-if="false">
              {{ preset.texts.scenario }} {{ currentPage + 1 }} / {{ answers.length }}
              <i class="fa fa-check text-success" v-if="this.answer(currentPage) !== 0"></i>
            </h1>
            <b-row class="mb-4 rounded text-primary" cols="2" cols-sm="2">
              <b-col>
                <div
                  class="ml-8 rounded font-size-lg border border-4"
                  :style="{ 'border-color': `rgba(105,147,255, ${textClass(currentPage, 'left')}) !important`, 'background-clip': 'padding-box' }"
                >
                  <p class="font-weight-bolder text-center text-dark">
                    {{ preset.texts.case_a }}
                  </p>
                  <p class="text-center text-dark lead">
                    {{ this.preset.questions.talents[currentPage]["text_left"] }}
                  </p>
                </div>
              </b-col>

              <b-col>
                <div
                  class="mr-8 rounded font-size-lg border border-4"
                  :style="{ 'border-color': `rgba(105,147,255, ${textClass(currentPage, 'right')}) !important`, 'background-clip': 'padding-box' }"
                >
                  <p class="font-weight-bolder text-center text-dark">
                    {{ preset.texts.case_b }}
                  </p>
                  <p class="text-center text-dark lead">
                    {{ this.preset.questions.talents[currentPage]["text_right"] }}
                  </p>
                </div>
              </b-col>
            </b-row>

            <b-row class="mb-4 p-2 rounded text-primary" cols="12">
              <b-col class="p-3 rounded font-size-lg">
                <la-range-talents v-model="this.answers[currentPage]" :texts="preset.texts"></la-range-talents>
              </b-col>
            </b-row>
            <b-row class="mb-4 p-2 rounded text-primary" cols="4" v-if="false">
              <b-col class="d-flex p-1">
                <button
                  class="btn w-100 text-center p-0"
                  style="min-height: 50px"
                  :class="{
                    'btn-primary': answer(index) === 1,
                    'btn-outline-primary': answer(index) !== 1,
                  }"
                  v-html="preset.texts.always_a"
                  @click="setValue(index, 1)"
                ></button>
              </b-col>
              <b-col class="d-flex p-1">
                <button
                  class="btn w-100 text-center p-1"
                  style="min-height: 50px"
                  :class="{
                    'btn-primary': answer(index) === 2,
                    'btn-outline-primary': answer(index) !== 2,
                  }"
                  @click="setValue(index, 2)"
                  v-html="preset.texts.often_a"
                ></button>
              </b-col>

              <b-col class="d-flex p-1">
                <button
                  class="btn w-100 text-center p-1"
                  style="min-height: 50px"
                  :class="{
                    'btn-primary': answer(index) === 3,
                    'btn-outline-primary': answer(index) !== 3,
                  }"
                  @click="setValue(index, 3)"
                  v-html="preset.texts.often_b"
                ></button>
              </b-col>
              <b-col class="d-flex p-1">
                <button
                  class="btn w-100 text-center p-1"
                  style="min-height: 50px"
                  :class="{
                    'btn-primary': answer(index) === 4,
                    'btn-outline-primary': answer(index) !== 4,
                  }"
                  @click="setValue(index, 4)"
                  v-html="preset.texts.always_b"
                ></button>
              </b-col>
            </b-row>
          </div>
        </transition>
      </b-card-body>
      <b-card-footer class="d-flex justify-content-between align-items-center">
        <button class="btn btn-primary" @click="setCurrentPage(currentPage - 1)" v-if="currentPage !== 0">{{ $t("GENERAL.PREVIOUS") }}</button>
        <div v-if="currentPage === 0"></div>
        <button class="btn btn-primary" @click="setCurrentPage(currentPage + 1)">{{ $t("GENERAL.NEXT") }}</button>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import TheToaster from "@/core/services/the-toaster";
import LaRangeTalents from "@/view/pages/content-engines/TalentsEngine/components/LaRangeTalents/LaRangeTalents";

export default {
  name: "talent-mechanic",
  components: { LaRangeTalents },
  props: {
    preset: Object,
    saveContent: Object,
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    answers() {
      return this.saveContent.saveData.answers.talents;
    },

    currentPage: {
      get() {
        return this.saveContent.saveData.talents_page;
      },
      set(value) {
        let self = this;
        self.saveContent.saveData.talents_page = Math.min(Math.max(value, 0), self.answers.length - 1);
      },
    },
  },
  methods: {
    setCurrentPage(page) {
      if (page > this.saveContent.saveData.talents_page && this.answer(this.saveContent.saveData.talents_page) === 0) {
        TheToaster.warning(this.preset.texts.choose_one, false);
        return;
      }
      this.show = false;
      let self = this;
      // eslint-disable-next-line vue/no-mutating-props
      this.currentPage = page;

      if (page === this.answers.length) {
        this.$emit("nextStep");
      } else {
        setTimeout(() => {
          self.show = true;
        }, 400);
      }
    },
    textClass(line, side) {
      let val = this.answer(line);
      if (val === 0) return 0;
      switch (side) {
        case "left":
          if (val <= 50) {
            return (100 - val * 2) / 100;
          } else {
            return 0;
          }
        case "right":
          if (val > 50) {
            return ((val - 50) * 2) / 100;
          } else {
            return 0;
          }
      }
    },

    answer(line) {
      return this.answers[line]?.r ?? 0;
    },
    setValue(line, value) {
      this.answers[line].r = value;
    },
  },
  mounted() {
    this.show = true;
  },
};
</script>
