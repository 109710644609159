<template>
  <div class="SkillRange">
    <hr />
    <b-row class="mb-4 mt-8 text-dark d-flex">
      <b-col class="text-center">
        <h2>{{ texts.scenario }} {{ questionNumber }}</h2>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col class="rounded p-2 d-flex">
        <i class="fa fa-check text-success fa-2x mx-2" v-if="answer > 0"></i>
        <span class="h6 mt-2 ml-2 text-dark lead font-weight-bold"> {{ text }}</span>
      </b-col>
    </b-row>
    <b-row class="text-center d-flex mt-2 d-sm-none text-dark" no-gutters>
      <b-col> {{ answer }} </b-col>
    </b-row>

    <b-row class="text-center d-none d-sm-flex mt-2 text-dark" no-gutters>
      <b-col v-if="answer === 0">
        <span class="text-white">{{ answer }}</span>
      </b-col>
      <b-col>
        <span v-if="answer >= 1 && answer < 33">{{ answer }}</span>
      </b-col>
      <b-col>
        <span v-if="answer >= 33 && answer < 66">{{ answer }}</span>
      </b-col>
      <b-col>
        <span v-if="answer >= 66">{{ answer }}</span>
      </b-col>
    </b-row>
    <b-row class="text-center mt-2" no-gutters>
      <b-col class="slider-h slider-top-left" :class="answer >= 1 && answer < 33 ? 'bg-danger' : 'bg-light-primary'"> </b-col>
      <b-col class="slider-margin slider-h" :class="answer >= 33 && answer < 66 ? 'bg-warning' : 'bg-light-primary'"> </b-col>
      <b-col class="slider-margin slider-h" :class="answer >= 66 ? 'bg-success' : 'bg-light-primary'"> </b-col>
    </b-row>

    <b-row>
      <b-col> <input @mousedown="mousedown" type="range" class="w-100 slider" min="1" max="100" v-model="answer" /></b-col>
    </b-row>
  </div>
</template>

<style scoped>
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 14px;
  background: #e5f2ff;

  outline: none;
  opacity: 0.9;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
}

.slider-h {
  height: 4px;
}
.slider-margin {
  border-left: 2px solid black;
}
.slider-top-left {
  border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
}

.slider-top-right {
  border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  border: 2px solid #e5f2ff;
  -webkit-appearance: none;
  appearance: none;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #6993ff;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  border: 2px solid #e5f2ff;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #6993ff;
  cursor: pointer;
}
</style>

<script>
export default {
  name: "skill-range",
  props: {
    preset: Object,
    value: Object,
    texts: Object,
  },
  data() {
    return {};
  },
  computed: {
    text() {
      return this.preset.question;
    },
    questionNumber() {
      return this.preset.index;
    },
    answer: {
      get() {
        return this.value.r <= 0 ? 0 : this.value.r;
      },
      set(value) {
        // eslint-disable-next-line vue/no-mutating-props
        this.value.r = value;
      },
    },
  },
  methods: {
    mousedown() {
      if (this.answer === 0) this.answer = 1;
    },
  },
  mounted() {},
};
</script>
