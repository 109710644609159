<template>
  <div class="TalentsEngine">
    <div v-if="saveContent.progress !== 100">
      <transition name="slide-fade">
        <talents-intro v-if="step === 0 && show === 'step'" @nextStep="walkStep" :preset="preset" />
      </transition>
      <transition name="slide-fade">
        <talent-mechanic v-if="step === 1 && show === 'step'" @nextStep="walkStep" :preset="preset" :save-content="saveContent" />
      </transition>
      <transition name="slide-fade">
        <skills-intro v-if="step === 2 && show === 'step'" @nextStep="walkStep" :preset="preset" />
      </transition>
      <transition name="slide-fade">
        <skill-mechanic v-if="step === 3 && show === 'step'" @nextStep="walkStep" :preset="preset" :save-content="saveContent" />
      </transition>
    </div>
    <!--    <talent-mechanic v-if="step === 1" @nextStep="talentProgress++" :preset="preset" />-->
    <transition name="slide-fade">
      <div v-if="saveContent.progress === 100 && currentToken !== ''">
        <div class="card">
          <div class="card-body text-dark">
            <run-content-last-page
              :engine="saveContent.engine"
              :save-content="saveContent"
              :currentToken="currentToken"
              :content-codename="preset.codename"
              text="GENERAL.CONTENT_LAST_PAGE"
            ></run-content-last-page>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<style src="./_TalentsEngine.css"></style>

<script>
import RunContentLastPage from "@/view/pages/content-components/RunContentLastPage/RunContentLastPage";
import TalentsIntro from "@/view/pages/content-engines/TalentsEngine/components/TalentsIntro/TalentsIntro";
import SkillsIntro from "@/view/pages/content-engines/TalentsEngine/components/SkillsIntro/SkillsIntro";
import TalentMechanic from "@/view/pages/content-engines/TalentsEngine/components/TalentMechanic/TalentMechanic";
import SkillMechanic from "@/view/pages/content-engines/TalentsEngine/components/SkillMechanic/SkillMechanic";

export default {
  name: "talents-engine",
  components: { SkillMechanic, TalentMechanic, SkillsIntro, TalentsIntro, RunContentLastPage },
  props: {
    preset: Object,
    saveContent: Object,
    currentToken: String,
  },
  data() {
    return {
      show: "step",
      saveFn: null,
    };
  },
  computed: {
    answers: {
      get() {
        return this.saveContent.saveData.answers;
      },
    },
    step: {
      set(value) {
        let self = this;
        this.show = "wait";
        self.saveContent.saveData.step = value;
        setTimeout(() => {
          self.show = "step";
        }, 600);
      },
      get() {
        return this.saveContent.saveData.step ?? 0;
      },
    },
    talentProgress: {
      get() {
        return this.answers.talents.reduce((acc, cur) => acc + (cur.r === 0 ? 0 : 1), 0) / this.answers.talents.length;
      },
    },
    skillProgress: {
      get() {
        return this.answers.skills.reduce((acc, cur) => acc + (cur.r === 0 ? 0 : 1), 0) / this.answers.skills.length;
      },
    },
  },
  methods: {
    walkStep() {
      this.step++;
      this.saveData();
      this.keepSaving();
    },
    keepSaving() {
      if (this.saveContent.progress < 100) {
        this.saveFn = setTimeout(() => {
          if (this.intro !== 0) {
            this.saveData();
          } else {
            clearTimeout(this.saveFn);
          }
          this.keepSaving();
        }, 20000);
      }
    },

    saveData() {
      clearTimeout(this.saveFn);
      let self = this;
      let progressTalents = this.talentProgress * 50;
      let progressSkills = this.skillProgress * 50;
      self.saveContent.progress = Math.floor(progressTalents + progressSkills);

      this.$emit("save-data", this.saveContent);
    },
  },
  mounted() {
    // for (let i = 0; i < this.answers.length - 20; i++) {
    //   this.answers[i].r = 10;
    // }
    // for (let i = 0; i < 174; i++) {
    //   this.answers.talents[i].r = Math.round(Math.random() * 99) + 1;
    // }
    // // eslint-disable-next-line vue/no-mutating-props
    // this.saveContent.saveData.talents_page = 174;
    // for (let i = 0; i < 99; i++) {
    //   this.answers.skills[i].r = Math.round(Math.random() * 99) + 1;
    // }
    // // eslint-disable-next-line vue/no-mutating-props
    // this.saveContent.saveData.skills_page = 1;

    this.keepSaving();
  },
};
</script>
